.form {
  > * {
    margin-top: 20px;
  }
}

.chip {
  height: 25px;
  width: 25px;
  display: inline-block;
  top: 6px;
  position: relative;
  padding-right: 10px;
}
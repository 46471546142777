.chip {
  height: 25px;
  width: 25px;
  display: inline-block;
  top: 6px;
  position: relative;
  padding-right: 10px;
}

.loader {
  position: absolute;
  height: 70px;
  width: 70px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spacer {
  min-height: 20px;
}

.pinNumber {
  height: 3.5em !important;
  width: 3.5em !important;
  @media (max-width: 600px) {
    height: calc(25vw - 30px) !important;
    width: calc(25vw - 30px) !important;
    font-size: 40px !important;
  }
}

.pinPad {
  margin-left: auto;
  margin-right: auto;
}

.pinDialogContent {
  @media (max-width: 600px) {
  }
}

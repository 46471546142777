.App {
  text-align: center;
  background-color: #282c34;

  .backdrop {
    @media (max-width: 600px) {
      // prevent backdrop-image from showing briefly
      //  on small screens where we have a takeover dialog
      background-size: 0;
    }

    background-color: #282c34;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .logo {
    @media (max-width: 600px) {
      // prevent logo from showing briefly
      //  on small screens where we have a takeover dialog
      display: none;
    }

    position: absolute;
    left: 50px;
    bottom: 50px;
  }
}
